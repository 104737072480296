@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Bebas+Neue&family=Prompt:wght@100;200;300;500;600&display=swap);
@font-face {
  font-family: 'Andale';
  src: url(../../static/media/ANDALEMO.a8b962f3.TTF);
}

@font-face {
  font-family: 'CentraleBold';
  src: url(../../static/media/centrale_bold.04ea56f3.otf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#selected {
  border: 5px solid orange;
}


.vjs-big-play-centered {
    border-radius: 5px;
    border: 0px solid white !important;
    box-shadow: none !important; 
}

.vjs-big-play-button {
    font-size: 5em !important;
    width: 1.5em !important;
    height: 1.5em !important;
    left: 55% !important;
    background-color: #cbfb45 !important;
    border: 0px !important;
    border-radius: 3em !important;  
    color: black !important;
}

