.vjs-big-play-centered {
    border-radius: 5px;
    border: 0px solid white !important;
    box-shadow: none !important; 
}

.vjs-big-play-button {
    font-size: 5em !important;
    width: 1.5em !important;
    height: 1.5em !important;
    left: 55% !important;
    background-color: #cbfb45 !important;
    border: 0px !important;
    border-radius: 3em !important;  
    color: black !important;
}
